<template>
	<v-app>
		<div class="login-page">
			<div class="box">
				<div class="left">
					<h3>Регистрация</h3>
					<button type="button" class="register-btn">Регистрация</button>
				</div>
				<div class="right">
					<h3>Войти в аккаунт</h3>
					<button type="button" class="login-btn">Логин</button>
				</div>
				<div class="form">

								
					<div class="login-form">
						<h3>Войти</h3>
						<v-form v-if="!forgotPassword" ref="loginForm" @submit.prevent="handleSubmit">
							<v-text-field
									v-model="login"
									label="Номер телефона / Email"
									type="text"
									:rules="[v => !!v || 'Обязательное поле']"
									required
							></v-text-field>

							<v-text-field
								label="Пароль"
								type="password"
								prepend-icon="mdi-key"
								v-model="password"
								:rules="[v => !!v || 'Password is required']"
							></v-text-field>

							<div class="form-group">
								<v-btn 
									dark color="indigo lighten-1"
									class="login mt-3" block
									type="submit"
									:loading="loading">
								Войти
								</v-btn> 
							</div>

							<div class="form-group">
								<v-btn block small text @click="forgotPassword = true">
									Забыл пароль
								</v-btn>
							</div>
						</v-form>
						<v-form v-else ref="forgotPasswordForm" @submit.prevent="Object.keys(resetData).length != 0 ? newPasswordSubmit() : forgotPasswordRequestCode()">
							<v-text-field
								v-model="login"
								label="Номер телефона/ Email"
								type="text"
								:rules="[v => !!v || 'Обязательное поле']"
								required
							></v-text-field>
							<template v-if="Object.keys(resetData).length != 0">
								<template v-if="resetData.duration">
									<div class="caption">Вы можете повторно запросить код через {{resetData.duration}} секунд.</div>
								</template>
								<v-btn v-else small text @click="resendCodeRequest(resetData, setForgotPasswordCountDown)" :disabled="loading">
									Запросить код повторно
								</v-btn>

								<v-text-field
									color="indigo lighten-1"
									label="Введите код, отправленный на указанный адрес"
									type="text"
									v-model="resetData.code"
									required
									:rules="[v => !!v || 'Обязательное поле']"
								></v-text-field>

								<v-text-field
									color="indigo lighten-1"
									label="Введите новый пароль"
									type="text"
									v-model="resetData.password"
									required
									:rules="[v => !!v || 'Обязательное поле']"
								></v-text-field>
							</template>

							<div class="form-group">
								<v-btn 
									block
									dark color="indigo lighten-1"
									class="login mt-3 btn-bg1"
									@click="Object.keys(resetData).length != 0 ? newPasswordSubmit() : forgotPasswordRequestCode()"
									:loading="loading">
								Восстановить
								</v-btn> 
							</div>

							<div class="form-group">
								<v-btn block small text @click="forgotPassword = false">
									Войти
								</v-btn>
							</div>
						</v-form>
					</div>

					<div class="register-form form-hidden">
						<h3>Регистрация</h3>
						<v-form ref="registrationForm" @submit.prevent="handleRegistration">
							<v-text-field 
								v-if="!registerViaPhoneNumber"
								label="Email"
								type="text"
								v-model="userEmail"
								:rules="[v => !!v || 'Обязательное поле', v => /.+@.+\..+/.test(v) || 'Введите корректный E-mail']"
							></v-text-field>

							<v-text-field
								v-else
								v-model="userPhoneNumber"
								:label="'Номер телефона'"
								prefix="+"
								type="number"
								@wheel="$event.target.blur()"
								:rules="[v => !!v || 'Обязательное поле', v => !v || v.match(/\d/g).length === 11 || 'Неправильный формат телефона']"
								required
							></v-text-field>

							<div class="form-group">
								<v-btn block small text @click="registerViaPhoneNumber = !registerViaPhoneNumber">
									Зарегистрироваться через {{registerViaPhoneNumber ? 'email' : 'номер телефона'}}
								</v-btn>
							</div>

							<v-text-field
								label="Пароль"
								type="password"
								v-model="userPassword"
								:rules="[v => !!v || 'Обязательное поле']"
							></v-text-field>

							<v-text-field
								label="Повторите пароль"
								type="password"
								v-model="userPasswordRepeat"
								:rules="[v => !!v || 'Обязательное поле', v => v == userPassword || 'Пароли не совпадают']"
							></v-text-field>

							<v-flex class="d-flex justify-end">
								<v-btn 
									height = 50px
									block
									dark color="indigo lighten-1"
									class="login mt-3 btn-bg1"
									type="submit"
									:loading="loading">
								Регистрация
								</v-btn> 
							</v-flex>
						</v-form>
					</div>
				</div>
			</div>
		</div>
		<v-dialog v-model="confirmRegistrationDialog" width="500">
			<v-card>
				<v-card-title class="headline lighten-2">Confirm Registration</v-card-title>
				<v-card-text>
					<v-form ref="confirmPhoneForm" @submit.prevent="handleRegistrationConfirm">
						<div>Введите код</div>
						<v-text-field
							color="indigo lighten-1"
							label="Введите код"
							type="text"
							v-model="confirmData.code"
							required
						></v-text-field>
						<template v-if="confirmData.duration">
							Вы можете повторно запросить код через {{confirmData.duration}} секунд.
						</template>
						<v-btn v-else small text @click="resendCodeRequest(confirmData, setCountDown)" :disabled="loading">
							{{ registerViaPhoneNumber ? 'Запросить повторный звонок' : 'Запросить повторную отправку письма' }}
						</v-btn>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="handleRegistrationConfirm" :loading="loading">
						Отправить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
	export default {
		mounted() {
			const 	loginBtn = document.querySelectorAll(".login-btn"),
					registerBtn = document.querySelectorAll(".register-btn"),
					lostPassBtn = document.querySelectorAll(".lost-pass-btn"),
					box = document.querySelector(".box"),
					loginForm = document.querySelector(".login-form"),
					registerForm = document.querySelector(".register-form");
					//lostPasswordForm = document.querySelector(".lost-password-form");

			registerBtn.forEach((btn) => {
				btn.addEventListener("click",() => {
					box.classList.add("slide-active");
					registerForm.classList.remove("form-hidden");
					loginForm.classList.add("form-hidden");
					//lostPasswordForm.classList.add("form-hidden");
				});
			});

			loginBtn.forEach((btn) => {
				btn.addEventListener("click",() => {
					box.classList.remove("slide-active");
					registerForm.classList.add("form-hidden");
					loginForm.classList.remove("form-hidden");
					//lostPasswordForm.classList.add("form-hidden");
				});
			});

			lostPassBtn.forEach((btn) => {
				btn.addEventListener("click",() => {
					registerForm.classList.add("form-hidden");
					loginForm.classList.add("form-hidden");
					//lostPasswordForm.classList.remove("form-hidden");
				});
			});

		},
		computed: {
			selectedLanguage() {
				return this.$store.state.language;
			}
		},

		data: () => ({
			login: null,
			password: null,
			lang: null,
			userEmail: null,
			userPassword: null,
			userPasswordRepeat: null,
			userPhoneNumber: null,
			loading: false,
			confirmData: {},
			confirmRegistrationDialog: false,
			intervalId: null,
			forgotPasswordintervalId: null,
			forgotPassword: false,
			resetData: {},
			registerViaPhoneNumber: false
		}),
		methods: {
			async handleSubmit() {
				if(this.$refs.loginForm.validate()) {
					this.loading = true;
					let sendData = {
						"password": this.password
					};
					let loginType = this.returnLoginType(this.login);

					if(loginType == 'phone') {
						sendData['phone'] = '+'+this.login;
					} else {
						sendData[loginType] = this.login;
					}
					await this.$http.post('/public/api/ru/v1/auth/login', sendData).then((response) => {
						this.loading = false;

						//this.$store.dispatch('alertAdd', {text: response.data.message, type: 'success'});

						this.$store.dispatch('login', response.data.data).then(() => {
							this.$router.push({ path: '/' });
						});
					}).catch(() => this.loading = false);
				}
			},
			async handleRegistration() {
				if(this.$refs.registrationForm.validate()) {
					this.loading = true;
					let sendData = {
						"password": this.userPassword
					};
					if(this.registerViaPhoneNumber){
						sendData['phone'] = '+'+this.userPhoneNumber;
					} else {
						sendData['email'] = this.userEmail;
					}
					await this.$http.post('/public/api/ru/v1/auth/register', sendData).then((response) => {
						this.loading = false;

						this.confirmData = response.data.data;
						this.confirmRegistrationDialog = true;
						this.setCountDown();
					}).catch(() => this.loading = false);
				}
			},
			async handleRegistrationConfirm() {
				if(this.$refs.confirmPhoneForm.validate()) {
					this.loading = true;if(this.registerViaPhoneNumber){
						this.confirmData['phone'] = '+'+this.userPhoneNumber;
					} else {
						this.confirmData['email'] = this.userEmail;
					}
					await this.$http.post('/public/api/ru/v1/auth/email/verify', this.confirmData).then(() => {
						this.loading = false;

						if(this.registerViaPhoneNumber){
							this.login = this.userPhoneNumber;
						} else {
							this.login = this.userEmail;
						}
						this.password = this.userPassword;

					}).catch(() => this.loading = false);
					this.handleSubmit();
				}
			},
			async resendCodeRequest(data, callback) { // TODO
				this.loading = true;
				await this.$http.post('/public/api/ru/v1/auth/resend', {
					"key" : data.key
				}).then((response) => {
					this.loading = false;
					data.duration = response.data.data.duration;
					data.key = response.data.data.key;
					this.$store.dispatch('alertAdd', {text: response.data.message, type: 'success'});
					callback();
				}).catch(() => this.loading = false);
			},
			async newPasswordSubmit() {
				if(this.$refs.forgotPasswordForm.validate()) {
					this.loading = true;
					
					this.resetData[this.returnLoginType(this.login)] = this.login;
					
					await this.$http.post('/public/api/ru/v1/auth/set-password', this.resetData).then(() => {
						this.loading = false;
						this.forgotPassword = false;
						this.$store.dispatch('alertAdd', {text: 'Пароль успешно восстановлен. Что бы войти в систему используйте новый пароль.', type: 'success'});
					}).catch(() => this.loading = false);
				}
			},
			async forgotPasswordRequestCode() {
				if(this.$refs.forgotPasswordForm.validate()) {
					let loginType = this.returnLoginType(this.login);

					if(loginType != 'email' && loginType != 'phone') {
						this.$store.dispatch('alertAdd', {text: 'Неправильный формат телефона или email.', type: 'error'});
						return false;
					}
					this.loading = true;
					let sendData = {};

					sendData[loginType] = this.login;

					await this.$http.post('/public/api/ru/v1/auth/password-recovery', sendData).then((response) => {
						this.loading = false;
						console.log(response.data);
						this.resetData = response.data.data;
						this.setForgotPasswordCountDown();
					}).catch(() => this.loading = false);
				}
			},
			setForgotPasswordCountDown() {
				this.forgotPasswordintervalId = setInterval(() => {
					if(this.resetData.duration === 0) {
						this.clearCountDown();
					} else {
						this.resetData.duration--;
					}
				}, 1000);
			},
			clearForgotPasswordCountDown() {
				clearInterval(this.forgotPasswordintervalId);
			},
			setCountDown() {
				this.intervalId = setInterval(() => {
					if(this.confirmData.duration === 0) {
						this.clearCountDown();
					} else {
						this.confirmData.duration--;
					}
				}, 1000);
			},
			clearCountDown() {
				clearInterval(this.intervalId);
			},
			returnLoginType(login) {
				if(this.isEmail(login)) {
					return 'email'
				} else if(login.match(/\d/g).length == login.length) {
					if(login.match(/\d/g).length == 11) {
						return 'phone';
					} else if(login.match(/\d/g).length == 12) {
						return 'iin';
					} else {
						return 'username';
					}
				} else {
					return 'username';
				}
			},
			isEmail(str) {
				const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(String(str).toLowerCase());
			}
		},
		watch: {
			confirmRegistrationDialog: function (v) {
				if(!v) {
					this.confirmData = {};
				}
			},
		}
	};
</script>
<style scoped>
	.login-page {
		min-height: 100vh;
		padding: 15px;
		background-color: #eeeeee;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
	.login-page .box {
		background-image: linear-gradient(to right top, rgb(100 115 201 / 44%), rgb(12 24 90 / 70%)), url(https://i.picsum.photos/id/1059/1920/1080.jpg?hmac=-FZD_1M-pnM3n8t2LW9HVnC5z-efGOywy4C4klBUx5E);
		background-position: center center;
		background-size: cover;
		/*background-position: -160px center;*/
		background-repeat: repeat-x;
		width: 100%;
		z-index: 100;
		border-bottom-right-radius: 30px;
		max-width: 1000px;
		min-height: 680px;
		box-shadow: 0 0 10px #dddddd;
		display: flex;
		flex-wrap: wrap;
		border-radius: 30px;
		position: relative;
		z-index: 1;
		transition: all 0.5s ease-in-out;
	}
	.login-page .box.slide-active {
		background-position:160px center;
	}
	.link {
		text-decoration: none;
		color: black;
		font-weight: 500;
		transition: .2s;
	}
	.link:hover {
		color: orange;
	}
	.main_heading {
		font-size: 40px;
		color: green;
	}
	.login-page .box .left,
	.login-page .box .right {
		width: 100%;
		max-width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding:40px 0;
	}

	.login-page .box .left h3,
	.login-page .box .right h3 {
		font-size: 35px;
		color: white;
		font-weight: 700;
		margin:0 0 15px;
	}

	.login-page .box .left .register-btn,
	.login-page .box .right .login-btn {
		background-color: skyblue;
		padding: 10px 35px;
		color:#ffffff;
		border: none;
		font-size: 16px;
		cursor: pointer;
		border-radius: 5px;
	}

	.login-page .box .left .register-btn:focus,
	.login-page .box .right .login-btn:focus {
		outline: none;
	}
	.login-page .box .form {
		position: absolute;
		background-color: #ffffff;
		height: 100%;
		width: 50%;
		right:0;
		top:0;
		padding:30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		transition: all 0.5s ease-in-out;
	}
	.login-page .box.slide-active .form {
		right: 50%;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}


	.login-page .box .form h3 {
		font-size: 20px;
		color: black;
		margin:0 0 30px;
		font-weight: 700;
		font-size: 30px;
		text-align: center;
	}

	.login-page .box .form .form-group {
		margin-bottom: 20px;
	}

	.login-page .box .form .lost-password-form,
	.login-page .box .form .login-form,
	.login-page .box .form .register-form {
		transition: all 0.5s ease-in-out;
	}

	.login-page .box .form .form-control {
		width: 100%;
		height: 45px;
		font-size: 16px;
		color: #000000;
		border:none;
		border-bottom:1px solid #cccccc;
		padding:0;
	}
	.login-page .box .form .form-control:focus {
		outline: none;
	}
	.login-page .box .form label {
		font-size: 16px;
		color:#555555;
	}

	.login-page .box .form .btn-bg1 {
		background-color: #f0ad4e;
	}

	.login-page .box .form .btn-bg2 {
		background-color: #5bc0de;
	}

	.login-page .box .form p {
		margin-top:20px;
		text-align: center;
	}
	.login-page .box .form h5 {
		font-size: 16px;
		color:#555555;
		margin:0 0 30px;
		font-weight: normal;
	}

	.login-page .box .form p a {
		font-size: 16px;
		text-decoration: none;
		display: inline-block;
		color:#3f51b5;
	}

	.login-page .box .form .form-hidden {
		max-height: 0;
		visibility: hidden;
		opacity:0;
		overflow:hidden;
	}


	.hide {
		display: none;
	}

	@media(max-width: 767px) {
		.login-page .box{
			flex:0 0 100%;
			max-width: 100%;
		}
	}
	@media(max-width: 575px) {
		.login-page .box .form{
			width: 100%;
			position: relative;
			right: auto;
			top: auto;
			height: auto;
		}
		.hide {
			display: inline-block;
		}
		.login-page .box .left,
		.login-page .box .right {
			visibility: hidden;
		}
		.login-page .box,
		.login-page .box.slide-active {
			background-position: center;
		}
		.login-page .box .left h3, .login-page .box .right h3 {
			font-size: 16px;
		}
		.login-page .box.slide-active .form {
			right: auto;
		}
	}
</style>