import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from "./config"
import router from './router'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		accessToken: localStorage.getItem('accessToken'),
		//userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
		alerts:[],
		language: localStorage.getItem('language') || 'ru',
	},

	mutations: {
		login(state, response) {
			state.accessToken = response.token;
			//state.userInfo = response.data;
			//this.commit("setUserInfo");
			localStorage.setItem('accessToken', state.accessToken);
			axios.defaults.headers.common['ApiToken'] = state.accessToken;
		},
		// setUserInfo(state) {
		// 	console.log('setUserInfo', state.userInfo);
		// 	localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
		// },
		setLanguage(state, lang) {
			localStorage.setItem('language', lang);
			state.language = lang;
		},
		alert(state, {alert, adding}) {
            adding ? state.alerts.push(alert)
				: state.alerts.shift()
		},
		logout(state) {
			state.accessToken = '';
			// state.userInfo = {};
			// localStorage.removeItem('userInfo');
			localStorage.removeItem('accessToken');
			delete axios.defaults.headers.common['ApiToken'];
		}
	},

	actions: {
		changeBaseUrl({getters/*, state*/}) {
			//axios.defaults.baseURL = getters.getBaseUrl+(state.accessToken ? '/'+state.language : '');
			axios.defaults.baseURL = getters.getBaseUrl;
		},
		alertAdd({commit}, alert) {
			commit('alert', { alert, adding: true});
			setTimeout(() => commit('alert', { alert, adding: false }), 5000);
		},
		login({commit}, response) {
			return new Promise((resolve) => {
				commit('login', response);
				resolve();
			})
		},
		logout({commit, dispatch}){
			commit('logout');
			dispatch('changeBaseUrl');
			router.replace('/login');
		}
	},

	getters: {
		getBaseUrl() {
			return config.API_URL;
		},
		// getUserInfo(state) {
		// 	return state.userInfo;
		// },
		getAccessToken(state) {
			return state.accessToken;
		},
		getLanguage(state) {
			return state.language;
		}
	}

});